window.bootstrap = require('bootstrap/js/src/modal');

window.addEventListener('load', function () {
  const isWhoWeArePage = document.getElementsByTagName('body')[0].classList.contains('page-template-page-who-we-are');

  if (isWhoWeArePage) {
    const cards = document.getElementsByClassName('md-card');

    // Foreach loop over cards, search if container a span with class 'badge' that contains the text 'MANAGER'. Ig has that class and name, adds the class 'bg-gray-500' to the card at md-card level.
    Array.from(cards).forEach(card => {
      const badge = card.querySelector('.badge');
      if (!!badge) {
        const badgeText = badge.innerText;

        if (badgeText.includes('MANAGER')) {
          card.classList.add('bg-gray-500');
        }
      }
    });
  }
});